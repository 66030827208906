import CardDetail from '_common/component/CardDetail';
import Text from '_common/component/Text';
import { UseFormReturn } from 'react-hook-form';
import BackCitizenIdCardSkeleton from 'styles/images/pages/KYC/back-citizen-id-card-skeleton.png';
import DefaultUserImage from 'styles/images/pages/KYC/default-user-image.png';
import FrontCitizenIdCardSkeleton from 'styles/images/pages/KYC/front-citizen-id-card-skeleton.png';
import { Col } from 'tera-dls';
import UploadKYCImage from './UploadKYCImage';

interface ICardImageUploadProps {
  form: UseFormReturn<any>;
}

const CardImageUpload = ({ form }: ICardImageUploadProps): JSX.Element => {
  const handleReceiveFrontCard = async (file: any) => {
    form.setValue('front_card_url', file.url);
  };

  const handleReceiveBackCard = async (file: any) => {
    form.setValue('back_card_url', file.url);
  };

  const handleReceiveFaceImage = async (file: any) => {
    form.setValue('face_image_url', file.url);
  };

  return (
    <Col className="flex flex-col gap-8">
      <CardDetail className="p-4 flex flex-col gap-4">
        <Text className="text-base font-medium">1. Ảnh CMT/CCCD mặt trước</Text>
        <UploadKYCImage
          imgPlaceholder={FrontCitizenIdCardSkeleton}
          imgURL={form.watch('front_card_url')}
          imgWidth={500}
          imgHeight={250}
          onReceiveImg={handleReceiveFrontCard}
        />
      </CardDetail>
      <CardDetail className="p-4 flex flex-col gap-4">
        <Text className="text-base font-medium">2. Ảnh CMT/CCCD mặt sau</Text>
        <UploadKYCImage
          imgPlaceholder={BackCitizenIdCardSkeleton}
          imgURL={form.watch('back_card_url')}
          imgWidth={500}
          imgHeight={250}
          onReceiveImg={handleReceiveBackCard}
        />
      </CardDetail>
      <CardDetail className="p-4 flex flex-col gap-4">
        <Text className="text-base font-medium">3. Ảnh khuôn mặt</Text>
        <UploadKYCImage
          imgPlaceholder={DefaultUserImage}
          imgURL={form.watch('face_image_url')}
          imgWidth={300}
          imgHeight={300}
          imgClassName="w-[300px] h-[300px]"
          onReceiveImg={handleReceiveFaceImage}
        />
      </CardDetail>
    </Col>
  );
};

export default CardImageUpload;
