import { config, endpoint } from '_common/constants/common';
import UploadFiles from '_common/dof/UploadFiles';
import { useStores } from '_common/hooks';
import Axios from 'axios';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { useEffect, useRef, useState } from 'react';
import ImgCameraCitizenCardPlaceholder from 'styles/images/pages/KYC/camera-citizen-card-placeholder.png';
import { Button, Col, Row, notification } from 'tera-dls';

type NavigatorWithGetUserMedia = Navigator & {
  webkitGetUserMedia?: (
    constraints: MediaStreamConstraints,
    successCallback: (stream: MediaStream) => void,
    errorCallback: (error: any) => void,
  ) => void;
  mozGetUserMedia?: (
    constraints: MediaStreamConstraints,
    successCallback: (stream: MediaStream) => void,
    errorCallback: (error: any) => void,
  ) => void;
  msGetUserMedia?: (
    constraints: MediaStreamConstraints,
    successCallback: (stream: MediaStream) => void,
    errorCallback: (error: any) => void,
  ) => void;
};

interface IUploadKYCImageProps {
  imgPlaceholder: any;
  imgURL: string;
  imgWidth: number;
  imgHeight: number;
  onReceiveImg: (file: any) => void;
  imgClassName?: string;
}

const UploadKYCImage = ({
  imgPlaceholder,
  imgURL,
  imgWidth,
  imgHeight,
  onReceiveImg,
  imgClassName,
}: IUploadKYCImageProps): JSX.Element => {
  const {
    authStore: { token: tokenModule, device: deviceModule },
  } = useStores();
  const authToken = tokenModule;

  const [isOpenCamera, setIsOpenCamera] = useState(false);
  const [stream, setStream] = useState<MediaStream | null>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    if (!isOpenCamera) return;

    const navigatorWithGetUserMedia = navigator as NavigatorWithGetUserMedia;

    const getUserMedia =
      navigator.mediaDevices?.getUserMedia ||
      navigatorWithGetUserMedia.webkitGetUserMedia ||
      navigatorWithGetUserMedia.mozGetUserMedia ||
      navigatorWithGetUserMedia.msGetUserMedia;

    if (getUserMedia) {
      const video = videoRef.current;

      if (navigator.mediaDevices?.getUserMedia) {
        navigator.mediaDevices
          .getUserMedia({ video: true })
          .then((mediaStream: MediaStream) => {
            setStream(mediaStream);

            if (video) {
              video.srcObject = mediaStream;
              video.play();
            }
          })
          .catch((error: any) => {
            console.error('Error accessing camera: ', error);
          });
      } else {
        getUserMedia.call(
          navigator,
          { video: true },
          (mediaStream: MediaStream) => {
            setStream(mediaStream);

            if (video) {
              video.srcObject = mediaStream;
              video.play();
            }
          },
          (error: any) => {
            console.error('Error accessing camera: ', error);
          },
        );
      }
    } else {
      console.error('getUserMedia is not supported in this browser.');
    }
  }, [isOpenCamera]);

  const stopCamera = () => {
    if (stream) {
      stream.getTracks().forEach((track) => track.stop());
      setStream(null);
    }
  };

  const closeCamera = () => {
    stopCamera();
    setIsOpenCamera(false);
  };

  const captureImage = () => {
    const canvas = canvasRef.current;
    const video = videoRef.current;

    if (!canvas && !video) return;

    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;

    const context = canvas.getContext('2d');

    if (!context) return;

    context.drawImage(video, 0, 0, canvas.width, canvas.height);

    canvas.toBlob(async (blob) => {
      if (!blob) return;

      const file = new File([blob], 'account-kyc-image.png', {
        type: 'image/png',
      });

      const form = new FormData();
      form.append('file', file);
      form.append('app_id', config.appId);
      form.append('object_id', 'kyc');
      form.append('object_key', 'kyc');
      form.append('folder', 'kyc');
      form.append('secure_code', 'tera');

      try {
        const result = await Axios({
          method: 'POST',
          url: `${endpoint}/file/upload`,
          data: form,
          headers: {
            authorization: authToken ? `Bearer ${authToken}` : '',
            'device-code': deviceModule,
          },
        });

        onReceiveImg({
          file: file,
          url: result?.data?.data?.thumb,
          id: result?.data?.data?.id,
        });
        closeCamera();
      } catch (error) {
        notification.error({
          message: 'Tải file lên thất bại!',
        });
      }
    }, 'image/png');
  };

  return (
    <Col className="flex flex-col gap-4">
      <Col className="flex flex-col justify-center gap-4">
        <Row className="w-full flex justify-center">
          <img
            src={imgURL || imgPlaceholder}
            alt="Citizen ID Card Image"
            className={classNames('object-contain', imgClassName)}
            style={{ width: `${imgWidth}px`, height: `${imgHeight}px` }}
          />
        </Row>
        <Row className="flex">
          <UploadFiles
            object_id="kyc"
            object_key="kyc"
            folder="kyc"
            isView={false}
            isCount={false}
            accept=".png, .jpeg, .jpg"
            onReceiveFiles={onReceiveImg}
          >
            <Button htmlType="button" className="bg-blue-500">
              Chọn ảnh
            </Button>
          </UploadFiles>
          {isOpenCamera ? (
            <Button
              htmlType="button"
              className="bg-blue-500"
              onClick={closeCamera}
            >
              Đóng camera
            </Button>
          ) : (
            <Button
              htmlType="button"
              className="bg-blue-500"
              onClick={() => setIsOpenCamera(true)}
            >
              Mở camera
            </Button>
          )}
        </Row>
      </Col>
      {isOpenCamera && (
        <Col className="flex flex-col items-start gap-4">
          <Row className="w-full flex justify-center items-center">
            <video ref={videoRef} style={{ width: '100%' }} />
            <img
              src={ImgCameraCitizenCardPlaceholder}
              alt="Camera Citizen Card Placeholder"
              className="absolute"
              style={{ width: `80%`, height: `80%` }}
            />
          </Row>
          <canvas ref={canvasRef} className="hidden" />
          <Button
            htmlType="button"
            className="bg-blue-500"
            onClick={captureImage}
          >
            Chụp ảnh
          </Button>
        </Col>
      )}
    </Col>
  );
};

export default observer(UploadKYCImage);
